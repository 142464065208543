import { AxiosError } from 'axios';
import { BackendError } from '../types/backendErrors';

export function extractErrorMessageForToast(error: any): string {
  // Used to extract certain errors from the templates backend for display to users
  if (error?.response?.status === 403 || error?.response?.status === 401) {
    const detailMsg = error.response.data?.detail;
    if (detailMsg) {
      return detailMsg;
    }
  }
  if (
    error?.response?.data?.detail === 'Unable to create template' ||
    error?.response?.data?.message === "Can't update as template is not the latest version"
  ) {
    return 'This template has already been amended elsewhere, please refresh your browser and try again';
  }
  if (error?.response?.data?.body?.indexOf("Section title can't contain the following characters") > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Template prefix is already in use') > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Template title is already in use') > -1) {
    return error.response.data.body;
  }
  if (error?.response?.data?.body?.indexOf('Invalid attachment id') > -1) {
    return error.response.data.body;
  }

  return '';
}

export function formErrorMessageForToast(messagePrefix: string, error: any): string {
  const errorDetail = extractErrorMessageForToast(error);

  if (errorDetail === '') {
    return messagePrefix;
  }
  return messagePrefix + ': ' + errorDetail;
}

const DefaultErrorMessage = 'There was a problem communicating with the server.';

const extractMessageFromErrorArray = (errors: BackendError[]): string => {
  return errors && errors.length ? errors[0]!.description : DefaultErrorMessage;
};

export const extractMessageFromError = (e: AxiosError<any, any>): string => {
  // This is intended to work for schema validation error as well as custom error from MTBE
  if (e.response == null) {
    return DefaultErrorMessage;
  }
  const errorMessage = e.response.data.detail ?? e.response.data.message ?? e.response.data;
  if (typeof errorMessage === 'string') {
    return errorMessage;
  } else if (typeof errorMessage === 'object' && !Array.isArray(errorMessage)) {
    return extractMessageFromErrorArray(errorMessage.errors);
  }

  const errors = e.response.data.errors;
  return extractMessageFromErrorArray(errors);
};
